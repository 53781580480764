import { DKI, DomainInfo, Route, VisitDetails } from "src/api";
import { DomainRoute } from "@services/initial-calls";
import {
    replacePlaceholders,
    getOffersByPlacement,
    orderListByKeyword,
} from "./sharedUtils";
import { ServerResponse } from "http";
import {
    JSONValue,
    ListingItem,
    OfferListToShow,
    ServerRequest,
    TranslationContent,
} from "@lib/shared/types";
import { setOfferId, getOfferId } from "@lib/shared/cookies";

const prepositions = [
    "to",
    "the",
    "and",
    "at",
    "a",
    "for",
    "an",
    "but",
    "nor",
    "or",
    "so",
    "yet",
    "by",
    "in",
    "of",
    "on",
    "to",
    "up",
    "off",
    "over",
];

export const getTitleFromGenericRoute = (
    keyword: string,
    DKIs: DKI[] | null,
    genericRoutes: (Route | DomainRoute)[],
    genericRoute: string | string[] | undefined,
    ts: string | string[] | undefined | null,
    referer: string | string[] | undefined,
    replaceKeyword: string | string[] | undefined,
    trafficSourceNetwork: string | undefined | null,
    region: string | undefined,
    city: string | undefined,
    category: string | undefined,
): { formattedTitle: string | null; notFound: boolean; goToHome?: boolean } => {
    const keywordDKI = DKIs?.find((DKI) => {
        if (["disallowed", "ignored"].includes(DKI.type)) {
            return (
                DKI.name.toLowerCase().replace(/ /g, "-") ===
                keyword?.toLowerCase()
            );
        } else if (["disallowed_any", "go_to_home"].includes(DKI.type)) {
            return keyword
                ?.toLowerCase()
                .includes(DKI.name.toLowerCase().replace(/ /g, "-"));
        } else {
            return undefined;
        }
    });

    if (keywordDKI) {
        switch (keywordDKI.type) {
            case "ignored":
                return { formattedTitle: null, notFound: false };
            case "disallowed":
            case "disallowed_any":
                return { formattedTitle: null, notFound: true };
            case "go_to_home":
                return {
                    formattedTitle: null,
                    notFound: false,
                    goToHome: true,
                };
        }
    }

    if (
        (!ts || (!referer && trafficSourceNetwork !== "Propel Media")) &&
        !replaceKeyword
    ) {
        return { formattedTitle: null, notFound: false };
    }

    if (
        !genericRoutes.find(
            (item) =>
                item.route === genericRoute &&
                "category" in item &&
                category === item.category.name,
        )?.ignoreKeyword &&
        DKIs
    ) {
        let temp = replacePlaceholders(keyword, {
            region,
            category,
            city,
        });
        const matchedDKIs: string[] = [];

        let index: number = 0;

        DKIs.filter((i) => i.type === "allowed").forEach((DKI) => {
            // Create a regex that handles multi-word DKIs
            const escapedDKI = DKI.name
                .toLowerCase()
                .replace(/\s+/g, "[-\\s]+");
            const regex = new RegExp(
                `(-\\b${escapedDKI}\\b-|\\b${escapedDKI}-|-${escapedDKI}\\b)`,
                "gi",
            );

            // in case keyword is exactly one DKI
            const keywordMatchesDKI = new RegExp(
                `^${DKI.name.toLowerCase().replace(/\s+/g, "[\\s-]+")}$`,
                "i",
            ).test(temp);

            if (keywordMatchesDKI) {
                matchedDKIs.push(DKI.name);
                temp = "#0";
                return;
            }

            temp = temp.replace(regex, (e) => {
                matchedDKIs.push(DKI.name);
                if (e.endsWith("-") && e.startsWith("-")) {
                    return `-#${index++}-`;
                } else if (e.endsWith("-")) {
                    return `#${index++}-`;
                } else if (e.startsWith("-")) {
                    return `-#${index++}`;
                } else {
                    return `#${index++}`;
                }
            });
        });
        const finalWords: string[] = [];
        const words = temp.split("-");

        words.forEach((word, idx) => {
            if (word.startsWith("#")) {
                const index = parseInt(word.replace("#", ""), 10);
                finalWords.push(matchedDKIs[index]);
            } else if (
                prepositions.includes(word) &&
                idx !== 0 &&
                idx !== words.length - 1 &&
                !(
                    finalWords[idx - 1].endsWith(":") ||
                    finalWords[idx - 1].endsWith(".")
                )
            ) {
                finalWords.push(word.toLowerCase());
            } else {
                finalWords.push(word.charAt(0).toUpperCase() + word.slice(1));
            }
        });
        const formattedTitle = finalWords.join(" ");
        return { formattedTitle, notFound: false };
    }
    return { formattedTitle: null, notFound: false };
};

export const getTranslation = ({
    domain,
    locale,
}: {
    domain: string;
    locale: string;
}): TranslationContent | null => {
    const tenant = domain?.toLowerCase().slice(0, domain.indexOf("."));

    try {
        const content =
            require(`../i18n/${tenant}/${locale}.json`) as JSONValue;
        return { content };
    } catch {
        return null;
    }
};

export const getFilteredOffersByNumber = (offers: ListingItem[]) => {
    const filteredOffersByNumber = offers?.filter(
        (offer) =>
            offer?.proxiedNumber?.phoneNumber ||
            offer?.hiddenAttributes?.phoneNumber ||
            offer?.phoneValue ||
            offer?.attributes.phoneNumber,
    );
    return filteredOffersByNumber;
};

export const filterOffersByAttribute = (offers: ListingItem[]) => {
    const filteredOffers = offers?.filter(
        (offer) => offer?.placements?.skipMainOffersList !== true,
    );
    return filteredOffers;
};

export const offerListToShow = (getOfferListToShowObject: OfferListToShow) => {
    const { place, offers, req, res, categorySlug, isMobile } =
        getOfferListToShowObject;
    const k = req.query?.k as string;
    const _tf_oid = getOfferId(req);

    if (!_tf_oid && req.query?.oid) {
        setOfferId(req.query?.oid as string, req, res);
    }
    const finalOid = _tf_oid
        ? _tf_oid
        : req.query?.oid
        ? req.query?.oid
        : undefined;

    if (place === "thankYou") {
        const offersToShowOnThankyou = getOffersByPlacement(
            offers,
            "thankYou",
        ) as ListingItem[];
        const filteredOffersByNumber = getFilteredOffersByNumber(
            offersToShowOnThankyou,
        );
        const filteredOffersWithOutNumber = offersToShowOnThankyou?.filter(
            (offer) =>
                !(
                    offer?.proxiedNumber?.phoneNumber ||
                    offer?.hiddenAttributes?.phoneNumber ||
                    offer?.phoneValue ||
                    offer?.attributes.phoneNumber
                ),
        );

        let selectedOfferNumber;
        let orderedListOffers;
        if (filteredOffersByNumber?.length) {
            selectedOfferNumber = filteredOffersByNumber?.find(
                (offer) => offer?.id === parseInt(finalOid as string, 10),
            );
            if (!selectedOfferNumber) {
                selectedOfferNumber = filteredOffersByNumber[0];
            }

            orderedListOffers = [
                selectedOfferNumber,
                ...orderListByKeyword(k, filteredOffersWithOutNumber),
            ];
        } else {
            orderedListOffers = orderListByKeyword(k, offersToShowOnThankyou);
        }

        return orderedListOffers;
    }

    if (place === "exitModal") {
        const orderedListOffers = orderListByKeyword(
            k,
            getOffersByPlacement(offers, "exitModal") as ListingItem[],
        );

        if (categorySlug === "auto-accident") {
            const filteredOffersByNumber =
                getFilteredOffersByNumber(orderedListOffers);
            let selectedOfferNumber;

            if (filteredOffersByNumber?.length) {
                selectedOfferNumber = filteredOffersByNumber?.find(
                    (offer) => offer?.id === parseInt(finalOid as string, 10),
                );
                if (!selectedOfferNumber) {
                    selectedOfferNumber = filteredOffersByNumber[0];
                }
                return [selectedOfferNumber];
            } else {
                const finalList = isMobile
                    ? orderedListOffers.slice(0, 1)
                    : orderedListOffers;
                return finalList;
            }
        } else {
            const finalList = isMobile
                ? orderedListOffers.slice(0, 1)
                : orderedListOffers;
            return finalList;
        }
    }
    if (place === "header") {
        const filteredOffersBySlug = getOffersByPlacement(
            offers,
            "header",
            categorySlug,
        ) as ListingItem[];

        const filteredOffersByNumber =
            getFilteredOffersByNumber(filteredOffersBySlug);

        let offer;
        if (!filteredOffersByNumber?.length) {
            offer = null;
        } else {
            if (finalOid) {
                offer = filteredOffersByNumber?.find(
                    (offer) => offer?.id === parseInt(finalOid as string, 10),
                );

                if (!offer) {
                    offer = filteredOffersByNumber[0];
                }
            } else {
                offer = filteredOffersByNumber[0];
            }
        }
        return offer ? [offer] : [];
    }
    if (place === "stickyOffer") {
        const filteredOffersBySlug = getOffersByPlacement(
            offers,
            "showStickyOffer",
            categorySlug,
        ) as ListingItem[];
        let offer;
        if (finalOid) {
            offer = filteredOffersBySlug?.find(
                (offer) => offer?.id === parseInt(finalOid as string, 10),
            );
            if (offer) {
                return [offer];
            }
        }
        return filteredOffersBySlug.slice(0, 1);
    }

    if (place === "form") {
        const filteredOffers = filterOffersByAttribute(offers);
        const filteredOffersByNumber =
            getFilteredOffersByNumber(filteredOffers);
        const offerToShow = filteredOffersByNumber?.find(
            (el) => el.id === parseInt(finalOid as string, 10),
        );
        if (!offerToShow && filteredOffersByNumber?.length > 0) {
            return [filteredOffersByNumber[0]];
        }
        return offerToShow ? [offerToShow] : [];
    }
};

export const initialOffersCalls = (
    domain: DomainInfo,
    req: ServerRequest,
    res: ServerResponse,
    categoryListingOffer: ListingItem[] | null,
    VisitDetails: VisitDetails,
    categorySlug = "",
) => {
    const formOffer = offerListToShow({
        place: "form",
        offers: domain.defaultOffers,
        req,
        res,
    });

    const exitModalOffer = offerListToShow({
        place: "exitModal",
        offers: domain.defaultOffers,
        req,
        res,
        categorySlug: categorySlug,
        isMobile: VisitDetails.isMobile,
    });

    const thankYouOffers = offerListToShow({
        place: "thankYou",
        offers: domain.defaultOffers,
        res,
        req,
    });
    const offers = req?.query?.listingSlug
        ? categoryListingOffer
            ? categoryListingOffer
            : domain?.defaultOffers
        : domain?.defaultOffers;

    const headerOffer = offerListToShow({
        place: "header",
        offers: offers,
        req,
        res,
        categorySlug,
    });
    const stickyOffer = offerListToShow({
        place: "stickyOffer",
        offers: offers,
        req,
        res,
        categorySlug,
    });

    return [
        formOffer,
        exitModalOffer,
        thankYouOffers,
        headerOffer,
        stickyOffer,
    ];
};

export const hasSpecialCharacters = (slug: string) => {
    // Check for special characters
    const regex = /[^\w\s-]/;
    return regex.test(slug);
};
